<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          <v-row>
            <v-col>
              <v-card-title>
                <span class="">{{
                  (editedIndex === -1 ? $t("add") : $t("edit")) +
                  " " +
                  $t("tests.testProfileCharge")
                }}</span>
              </v-card-title>
            </v-col>

            <v-col cols="auto" align-self="center">
              <v-btn icon class="text-end" color="" @click="onClose">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  persistent-hint
                  v-model="editedItem.profileName"
                  :rules="[rules.required]"
                  :label="$t('tests.testName')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  persistent-hint
                  v-model="editedItem.profileCode"
                  :rules="[rules.required]"
                  :label="$t('tests.testCode')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  persistent-hint
                  v-model="editedItem.testPrice"
                  :label="$t('tests.testPrice')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  persistent-hint
                  v-model="editedItem.doctorAmount"
                  :label="$t('services.doctorAmount')"
                  type="number"
                  min="0"
                  :rules="[
                    (value) =>
                      !!value || value == 0 || $t('ThisFieldIsRequired'),
                  ]"
                ></v-text-field>
              </v-col>

              <v-col cols="11" md="11">
                <v-slider
                  v-model="editedItem.doctorPercentage"
                  hide-details=""
                  color="orange"
                  min="0"
                  max="100"
                  persistent-hint
                  :label="$t('services.doctorPercentage')"
                  :thumb-size="24"
                  thumb-label="always"
                ></v-slider>
              </v-col>
              <v-col cols="1" md="1" class="pa-0 pt-3 font-weight-medium">
                <div>{{ editedItem.doctorPercentage + " %" }}</div>
              </v-col>

              <v-col cols="12" md="12">
                {{ $t("tests.testNames") }}

                <v-card flat>
                  <v-card-title class="grey--text text--darken-1">
                  </v-card-title>
                  <v-card-subtitle>
                    <v-text-field
                      v-model="search"
                      :label="$t('search')"
                      outlined
                      flat
                      dense
                      hide-details
                      clearable
                      clear-icon="mdi-close-circle-outline"
                    ></v-text-field>
                  </v-card-subtitle>

                  <v-card-text>
                    <v-card height="300" style="overflow-y: scroll" flat>
                      <v-treeview
                        :items="testHeaders"
                        selectable
                        selection-type="leaf"
                        :search="search"
                        item-key="guid"
                        :open-on-click="true"
                        v-model="editedTestHeaders"
                      >
                        <template v-slot:label="{ item, leaf }">
                          <span v-if="!leaf">
                            {{ $t("tests.particularTypes." + item.name) }}
                          </span>
                          <span style="white-space: break-spaces" v-else>
                            {{ item.name }}
                          </span>
                        </template>
                      </v-treeview>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field
                  persistent-hint
                  v-model="editedItem.note"
                  :label="$t('notes')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            class="white--text"
            @click="save"
            :disabled="!valid"
            :min-width="100"
          >
            <v-icon>mdi-content-save-outline</v-icon>
            {{ $t("save") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="onClose">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: ["onClose", "dialog", "editedItem", "editedIndex", "resetValidation"],
  data() {
    return {
      valid: true,
      open: [1, 2],
      search: null,
      editedTestHeaders: [],
      testHeaders: [],
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },
  created() {
    this.$axios
      .get("TestProfileCharge/GetHeaderByGroup")
      .then((response) => {
        this.testHeaders = response.data.data;
      })
      .catch((e) => {
        this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
        console.log(e);
      });
  },
  watch: {
    resetValidation() {
      this.resetForm();
    },
    editedTestHeaders(val) {
      if (
        val.length == 1 &&
        (!this.editedItem.profileCode || !this.editedItem.profileName)
      ) {
        var header = this.testHeaders.filter(
          (f) => f.children.filter((fe) => fe.guid == val).length > 0
        )[0];

        var child = header.children.filter((f) => f.guid == val)[0];

        this.editedItem.profileCode = child.testCode;
        this.editedItem.profileName = child.testName;
      }
    },
    editedItem(val) {
      if (val && val.testHeaders && val.testHeaders.length > 0) {
        this.editedTestHeaders = val.testHeaders;
      }
    },
    // "editedItem.testHeaders": {
    //   handler: (val) => {
    //

    //       this.editedTestHeaders = val;
    //     }
    //   },
    //   deep: true,
    // },
  },
  computed: {},
  methods: {
    resetForm() {
      this.editedTestHeaders = [];
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    save() {
      var val = this.$refs.form.validate();
      if (val && this.editedTestHeaders.length > 0) {
        this.editedItem.testHeaders = this.editedTestHeaders;
        if (this.editedIndex > -1) {
          this.$axios
            .put(
              "TestProfileCharge/Update?guid=" + this.editedItem.guid,
              this.editedItem
            )
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.onClose();
                this.$emit("refreshTable");
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.$emit("refreshTable");
              console.log(e);
            });
        } else {
          this.$axios
            .post("TestProfileCharge/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.onClose();
                this.$emit("refreshTable");
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.$emit("refreshTable");
              console.log(e);
            });
        }
      } else {
        this.$toast.error(this.$t("tests.selectHeaderFirst"));
      }
    },
  },
};
</script>

<style></style>
